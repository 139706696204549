import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'
import { stripeElementStyles } from '@/stripe/element-styles'

import StripeSubscriptionHandler from '@/stripe/subscription'

// Connects to data-controller="subscription"
export default class extends Controller {
  static targets = [
    'form',
    'planIntervalField',
    'couponCodeField',
    'cardNumber',
    'cardExpiry',
    'cardCvc',
    'cardErrorMessage',
    'userZipCode',
    'planSummary',
    'overviewTable',
    'payButton'
  ]

  static values = {
    successUrl: String,
    userEmail: String
  }

  connect() {
    this.stripe = null
    this.cardDetails = null

    this.setupStripe()
  }

  async setupStripe() {
    this.connectToStripe().then(() => {
      this.mountStripeElements()
    })
  }

  async connectToStripe() {
    const stripeKey = document.querySelector('meta[name="stripe-key"]').getAttribute('content')
    this.stripe = await loadStripe(stripeKey)
  }

  mountStripeElements() {
    const elements = this.stripe.elements()

    // Create and mount card input elements
    const cardNumber = elements.create('cardNumber', { showIcon: true, style: stripeElementStyles })
    const cardExpiry = elements.create('cardExpiry', { style: stripeElementStyles })
    const cardCvc = elements.create('cardCvc', { style: stripeElementStyles })

    // And mount them
    cardNumber.mount(this.cardNumberTarget)
    cardExpiry.mount(this.cardExpiryTarget)
    cardCvc.mount(this.cardCvcTarget)

    // Organise them in an object for later
    this.cardDetails = { number: cardNumber, cardExpiry: cardExpiry, cvc: cardCvc }
  }

  applyPriceAndMonth(event) {
    const selectedPlan = event.target

    // Update plan summary text
    this.planSummaryTarget.innerHTML = selectedPlan.dataset.introText

    // Update overview and pay button text
    this.updateOverviewTable(selectedPlan.dataset.interval)

    if (selectedPlan.dataset.formattedAmountToPay) {
      this.updatePayButtonText(selectedPlan.dataset.formattedAmountToPay)
    }

    // Update plan_interval hidden form field
    this.planIntervalFieldTarget.value = selectedPlan.dataset.interval
  }

  updateOverviewTable(selectedPlanInterval) {
    this.overviewTableTargets.forEach((overviewTable) => {
      if (overviewTable.dataset.interval === selectedPlanInterval) {
        overviewTable.classList.remove('hidden')
      } else {
        overviewTable.classList.add('hidden')
      }
    })
  }

  updatePayButtonText(formattedAmountToPay) {
    const payButtonTextTag = this.payButtonTarget.querySelector('.text')
    payButtonTextTag.innerHTML = `Pay ${formattedAmountToPay}`
    payButtonTextTag.setAttribute('aria-label', `Pay ${formattedAmountToPay}`)
  }

  removeCouponCode(event) {
    event.preventDefault()
    this.couponCodeFieldTarget.value = 'clear'
    this.couponCodeFieldTarget.dispatchEvent(new Event('change'))
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  setPayButtonDefault() {
    this.payButtonTarget.dataset.isLoading = 'false'
    this.payButtonTarget.dataset.disabled = 'false'
  }

  setPayButtonLoading() {
    this.payButtonTarget.dataset.isLoading = 'true'
    this.payButtonTarget.dataset.disabled = 'true'
  }

  displayError(error) {
    const errorMessage =
      error?.message || 'There was an error creating your subscription. Please try again or contact support.'

    this.cardErrorMessageTarget.setAttribute('data-error-message', errorMessage)
    this.setPayButtonDefault()
  }

  clearErrors() {
    this.cardErrorMessageTarget.removeAttribute('data-error-message')
  }

  subscriptionSuccess() {
    // Remove invoice from localstorage because payment is now complete.
    localStorage.removeItem('latestInvoiceId')

    // Payment was successful. Provision access to your service.
    window.location.replace(this.successUrlValue)
  }

  pay(event) {
    // handle pay button
    event.preventDefault()

    this.clearErrors()
    this.setPayButtonLoading()

    // Check if zip code is present
    if (this.userZipCodeTarget.value.length < 1) {
      this.displayError(new Error('ZIP/Post code is required'))
      return
    }

    const selectedPlan = document.querySelector('input[name="subscription[stripe_plan_id]"]:checked')

    // Set coupon only if it matches the currently selected plan interval
    let couponCode = null
    if (
      this.couponCodeFieldTarget.dataset.interval === 'all' ||
      this.couponCodeFieldTarget.dataset.interval === selectedPlan.dataset.interval
    ) {
      couponCode = this.couponCodeFieldTarget.value
    }

    // Start a Stripe subscription
    const subscriptionHandler = new StripeSubscriptionHandler(
      this.stripe,
      this.cardDetails,
      this.billingDetails,
      selectedPlan.value,
      couponCode,
      this.subscriptionSuccess.bind(this),
      this.displayError.bind(this)
    )

    subscriptionHandler.startSubscriptionProcess()
  }

  get billingDetails() {
    return {
      email: this.userEmailValue,
      address: {
        postal_code: this.userZipCodeTarget.value
      }
    }
  }
}
