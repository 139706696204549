const stripeElementStyles = {
  base: {
    color: '#434242',
    fontFamily: '"dm-sans", "helvetica", sans-serif',
    fontSize: '16px',
    '::placeholder': {
      color: '#9a9a9a'
    }
  }
}

export { stripeElementStyles }
