// Based on stripe example: https://stripe.com/docs/billing/subscriptions/fixed-price

export default class StripeSubscriptionHandler {
  constructor(stripe, cardDetails, billingDetails, planId, couponCode = null, successCallback, errorCallback) {
    this.stripe = stripe
    this.cardDetails = cardDetails // example format: { number: cardNumber, cardExpiry: cardExpiry, cvc: cardCvc }
    this.billingDetails = billingDetails // example format: { email: email, address: {postal_code: postCode} }
    this.planId = planId
    this.couponCode = couponCode
    this.successCallback = successCallback
    this.errorCallback = errorCallback

    this.customerDetails = null
    this.paymentMethodId = null
  }

  async startSubscriptionProcess() {
    try {
      // 1. Create Stripe Customer from backend (POST /stripe_customer)
      this.customerDetails = await this.createStripeCustomer()

      // 2. Create Stripe payment method (POST all form data to https://api.stripe.com/v1/payment_methods)
      await this.createPaymentMethod()

      // 3. Create subscription from backend & then handle 2FA
      await this.createSubscription()

      // 4. Send success callback
      this.successCallback()
    } catch (error) {
      this.errorCallback(error)
    }
  }

  async createStripeCustomer() {
    const response = await fetch('/account/stripe_customers', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({})
    })

    if (!response.ok) {
      const errorBody = await response.json()
      throw { error: { message: `Creating Customer failed: ${errorBody.error.message}` } }
    }

    const result = await response.json()
    return result.customer
  }

  async createPaymentMethod() {
    const result = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardDetails.number,
      billing_details: this.billingDetails
    })

    if (result.error) {
      throw result.error
    }

    this.paymentMethodId = result.paymentMethod.id
  }

  async createSubscription() {
    const response = await fetch('/account/stripe_subscriptions', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        customer_id: this.customerId,
        payment_method_id: this.paymentMethodId,
        plan_id: this.planId,
        coupon_code: this.couponCode
      })
    })

    if (!response.ok) {
      const errorBody = await response.json()
      throw new Error(errorBody.error.message)
    }

    const result = await response.json()
    if (result.error) {
      throw result.error
    }

    const subscription = result.data.subscription

    // Some payment methods require a customer to do additional authentication with their financial institution.
    // Eg: 2FA for cards.
    // Handle payment actions if required
    const handledSubscription = await this.handlePaymentThatRequiresCustomerAction(subscription)

    // If attaching this card to a Customer object succeeds, but attempts to charge the customer fail.
    // You will get a requires_payment_method error.
    await StripeSubscriptionHandler.handleRequiresPaymentMethod(handledSubscription)
  }

  async handlePaymentThatRequiresCustomerAction(subscription) {
    const paymentMethodId = this.paymentMethodId

    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return subscription
    }

    const paymentIntent = subscription.latest_invoice?.payment_intent
    const pendingSetupIntent = subscription.pending_setup_intent

    if (paymentIntent) {
      await this.paymentIntentCustomerAction(paymentIntent, paymentMethodId)
    } else if (pendingSetupIntent) {
      await this.setupIntentCustomerAction(pendingSetupIntent, paymentMethodId)
    }

    return subscription
  }

  async paymentIntentCustomerAction(paymentIntent, paymentMethodId) {
    if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_payment_method') {
      const result = await this.stripe.confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })

      if (result.error) {
        throw result.error
      }
    }
  }

  async setupIntentCustomerAction(setupIntent, paymentMethodId) {
    if (setupIntent.status === 'requires_action' || setupIntent.status === 'requires_payment_method') {
      const result = await this.stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: paymentMethodId
      })

      if (result.error) {
        throw result.error
      }
    }
  }

  static async handleRequiresPaymentMethod(subscription) {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // Subscription is active, no customer actions required.
      return
    }

    const paymentIntent = subscription.latest_invoice?.payment_intent
    const pendingSetupIntent = subscription.pending_setup_intent

    if (paymentIntent?.status === 'requires_payment_method' || pendingSetupIntent?.status === 'requires_payment_method') {
      throw new Error('Your card has been declined.')
    }
  }

  get customerId() {
    return this.customerDetails['id']
  }
}
